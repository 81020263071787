import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import PoetryHeading from "../components/PoetryHeading"
import { Row, Col } from "react-bootstrap"
import Image from "gatsby-image"
import { Helmet } from "react-helmet"
const getImages = graphql`
  {
    fluid: file(relativePath: { eq: "thumbnail_The Eye of the Horse.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
const Poetry = () => {
  const data = useStaticQuery(getImages)
  return (
    <Layout>
      <Helmet>
        <title>Poetry - Pamela Mary Brown</title>
      </Helmet>
      <h2>Poetry</h2>
      <Row>
        <Col
          xs={{ order: "last", span: 12 }}
          sm={{ order: "last", span: 12 }}
          md={{ order: "first", span: 3 }}
          lg={{ order: "first", span: 3 }}
          xl={{ order: "first", span: 3 }}
        >
          <h6 className="text-center" style={{ fontSize: "13px" }}>
            The Eye of the Horse{" "}
          </h6>
          <Image fluid={data.fluid.childImageSharp.fluid} />
          <h6
            className="text-center"
            style={{ fontSize: "13px", marginTop: "7px" }}
          >
            photograph © Jan Voster
          </h6>
        </Col>

        <Col
          xs={{ order: "last", span: 12 }}
          sm={{ order: "last", span: 12 }}
          md={{ order: "first", span: 9 }}
          lg={{ order: "first", span: 9 }}
          xl={{ order: "first", span: 9 }}
          style={{ paddingRight: "0px", paddingLeft: "0px" }}
        >
          <PoetryHeading />
        </Col>
      </Row>
    </Layout>
  )
}
export default Poetry
